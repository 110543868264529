.App {
  height: inherit;
  text-align: center;
  display: grid;
  grid-template-columns: 64px 20px 1fr 2px;
  grid-template-rows: auto 1fr 5em;
  grid-template-areas:
    'side header header header'
    'side main main main'
    'side footer footer footer';
}

.Header {
  grid-area: header;
}

.Content {
  grid-area: main;
}

.Footer {
  grid-area: footer;
}

html,
body {
  height: 100%;
  /* background-color: #d1f9d1; */
  background-color: white;
  overflow-x: hidden;
}
