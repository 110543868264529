.dropzone {
  border: 2px dashed #ccc;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 200px;
}

.dropzone p {
  font-size: 20px;
  margin-bottom: 20px;
}

.dropzone input[type="file"] {
  display: none;
}

.dropzone img {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.dropzone div {
  margin-top: -20px;
}
